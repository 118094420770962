/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router';
import { styled } from '@mui/material/styles';
import animateScrollTo from 'animated-scroll-to';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import {
  Box,
  Grid,
  Stepper,
  Step,
  StepLabel,
  MenuItem,
  Typography,
  Stack,
  IconButton,
  Card,
  Container,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ButtonBase,
  Chip,
  Skeleton,
} from '@mui/material';

import CelebrationIcon from '@mui/icons-material/Celebration';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import { BusinessCenterOutlined, Person2Outlined, RemoveRedEye } from '@mui/icons-material';

import { graphqlApi } from 'src/hophop/api';
import { reduxFunc } from 'src/hophop/redux';
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from 'src/components/iconify/iconify';
import { useSnackbar } from 'src/components/snackbar';
import useFetchData from 'src/hophop/hooks/_useFetchData';
import { useSettingsContext } from 'src/components/settings';
import FormProvider from 'src/components/hook-form/form-provider';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { RHFAutocomplete, RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import {
  dayMapping,
  timeMapping,
  repeatIntervalTypes,
  hoursBeforeReservationExpiresList,
} from 'src/hophop/utils/_enums';

import { getDates } from 'src/hophop/utils';
import CreateActivityCard from './_CreateActivityCard';



const steps = ['Başlangıç', 'Temel Bilgiler', 'Detay Bilgiler', 'Bitiş'];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  transitionDuration: '1s',

  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
    transitionDuration: '1s',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#7DC9CF',
      transitionDuration: '1s',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#7DC9CF',
      transitionDuration: '1s',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
    transitionDuration: '1s',
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#7DC9CF',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#7DC9CF',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  transitionDuration: '1s',
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <PlayCircleOutlineOutlinedIcon />,
    2: <Person2Outlined />,
    3: <BusinessCenterOutlined />,
    4: <OutlinedFlagRoundedIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props?.icon)]}
    </ColorlibStepIconRoot>
  );
}

export function dateTo_yyyymmdd(date) {
  const d = new Date(date.toString());
  return [
    d.getFullYear(),
    (`0${d.getMonth() + 1}`).slice(-2),
    (`0${d.getDate()}`).slice(-2),
  ].join("-");
}


const CreateActivity = () => {

  const { id } = useParams();
  const dispatch = useDispatch();
  const settings = useSettingsContext();
  const addOrUpdateDatePopup = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const { handleFetchData } = useFetchData();
  const [activityCreateLoading, setActivityCreateLoading] = useState(false);

  const [isReview, setIsReview] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const { accessToken } = useSelector((state) => state?.auth);
  const { selectedActivityForModerator } = useSelector((state) => state?.activity);

  useEffect(() => {
    dispatch(reduxFunc.parameterFunc.fetchCreateActivityParametersForModeratorFunc({ accessToken }));
  }, [accessToken, dispatch])


  useEffect(() => {

    if (id) {
      dispatch(
        reduxFunc.activityFunc.fetchSelectedActivityForModeratorFunc({
          accessToken,
          activityId: id,
        })
      );
    } else {
      dispatch(reduxFunc.listingFunc.resetSelectedListing());
      dispatch(reduxFunc.activityFunc.resetSelectedActivity());
    }

  }, [accessToken, dispatch, id]);







  const handleNext = useCallback(() => {
    let newSkipped = skipped;
    if (skipped.has(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  }, [activeStep, skipped]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const NewActivitySchema = Yup.object().shape({
    additionalDetails: Yup.string(''),
    duration: Yup.string('').required('Gerekli!'), // int
    fee: Yup.string('').required('Gerekli!'), // free | paid
    listingId: Yup.string('').required('Gerekli!'),
    locationId: Yup.string('').required('Gerekli!'),
    price: Yup.string('').required('Gerekli!'), // int
    quota: Yup.number(0), // int
    title: Yup.string('').required('Gerekli!'), // string

    schedule: Yup.object({
      isRepetetive: Yup.boolean(), // **** bu backende gitmeyecek, sadece front için ****
      repeatedIntervalType: Yup.string('').required('Gerekli!'), // once | daily | weekly | monthly
      totalDayCount: Yup.string('').required('Gerekli!'), // int // bu kullanıcıya gösterme frontta arka planda hesapla
      days: Yup.array(''),

      startDate: null, // data input
      endDate: null, // data input
      day: Yup.array(''),
      startTime: Yup.string('').required('Gerekli!'),
      endTime: Yup.string('').required('Gerekli!'),
    }),
  });


  const defaultValues = useMemo(
    () => ({
      additionalDetails: selectedActivityForModerator?.additionalDetails ?? '',
      duration: selectedActivityForModerator?.duration ?? '', // int
      fee: selectedActivityForModerator?.fee ?? '', // free | paid
      hoursBeforeReservationExpires:
        selectedActivityForModerator?.hoursBeforeReservationExpires ?? '', // int
      isReservationRequired:
        selectedActivityForModerator?.isReservationRequired ?? '',
      listingId: selectedActivityForModerator?.listing?._id ?? '',
      locationId: selectedActivityForModerator?.location?._id ?? '',
      price: selectedActivityForModerator?.price ?? '', // int
      quota: selectedActivityForModerator?.quota ?? 0, // int
      title: selectedActivityForModerator?.title ?? '', // int

      schedule: {
        isRepetetive: selectedActivityForModerator?.schedule?.totalDayCount > 1, // **** bu backende gitmeyecek, sadece front için ****
        repeatedIntervalType:
          selectedActivityForModerator?.schedule?.repeatedIntervalType ?? 'once', // once | daily | weekly | monthly
        totalDayCount: selectedActivityForModerator?.schedule?.totalDayCount ?? '', // int // bu kullanıcıya gösterme frontta arka planda hesapla
        days:
          selectedActivityForModerator?.schedule?.days ??
          [
            // {
            //     "date": null,
            //     "day": null,
            //     "startTime": null
            //     "endTime": null,
            // }
          ],

        startDate: selectedActivityForModerator
          ? new Date(selectedActivityForModerator?.schedule?.startDate)
          : null, // data input
        endDate: selectedActivityForModerator
          ? new Date(selectedActivityForModerator?.schedule?.endDate)
          : null, // data input
        day: [],
        startTime: selectedActivityForModerator?.schedule?.[0]?.startTime ?? '',
        endTime: selectedActivityForModerator?.schedule?.[0]?.endTime ?? '',
      },
    }),
    [selectedActivityForModerator]
  );

  const methods = useForm({
    resolver: yupResolver(NewActivitySchema),
    defaultValues,
    mode: 'all',
  });

  const { watch, handleSubmit, formState, trigger, setValue, control, reset } = methods;

  const { errors } = formState;
  const values = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset, id]);

  useEffect(() => {
    if (!values?.schedule?.isRepetetive) {
      setValue('schedule.repeatedIntervalType', 'once');
      setValue('schedule.totalDayCount', 1);
      setValue('schedule.onceDay', 1);
    } else {
      setValue(
        'schedule.repeatedIntervalType',
        values?.schedule?.repeatedIntervalType === 'once'
          ? ''
          : values?.schedule?.repeatedIntervalType
      );
      setValue('schedule.totalDayCount', values?.schedule?.days?.length);
      setValue('schedule.onceDay', '');
    }
  }, [
    setValue,
    values?.schedule?.days?.length,
    values?.schedule?.isRepetetive,
    values?.schedule?.repeatedIntervalType,
  ]);

  useEffect(() => {

    if (!id) {

      if (values?.schedule?.repeatedIntervalType === 'once') {

        if (values?.schedule?.startDate) {
          setValue('schedule.days', [
            {
              date: new Date(dateTo_yyyymmdd(values?.schedule?.startDate)), // date
              day: new Date(values?.schedule?.startDate).getDay(), // int
              startTime: values?.schedule?.startTime, // int
              endTime: values?.schedule?.endTime, // int
            },
          ]);
          // setValue("schedule.endDate", values?.schedule.startDate)
        }

      } else if (values?.schedule?.repeatedIntervalType === 'daily') {

        if (values?.schedule?.startDate) {
          const dates = getDates(values?.schedule?.startDate, values?.schedule?.endDate, 1);
          setValue(
            'schedule.days',
            dates?.map((item) => ({
              date: new Date(dateTo_yyyymmdd(item)), // date
              day: new Date(item).getDay(), // int
              startTime: values?.schedule?.startTime, // int
              endTime: values?.schedule?.endTime, // int
            }))
          );
        }

      } else if (values?.schedule?.repeatedIntervalType === 'weekly') {

        const dates = getDates(
          values?.schedule?.startDate,
          values?.schedule?.endDate,
          values?.schedule?.day?.map((item) => item?.value)
        );
        setValue(
          'schedule.days',
          dates?.map((item) => ({
            date: item, // date
            day: new Date(item).getDay(), // int
            startTime: values?.schedule?.startTime, // int
            endTime: values?.schedule?.endTime, // int
          }))
        );
      } else if (values?.schedule?.repeatedIntervalType === 'monthly') {
        const dates = getDates(values?.schedule?.startDate, values?.schedule?.endDate, 'monthly');
        setValue(
          'schedule.days',
          dates?.map((item) => ({
            date: item, // date
            day: new Date(item).getDay(), // int
            startTime: values?.schedule?.startTime, // int
            endTime: values?.schedule?.endTime, // int
          }))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setValue,
    values?.schedule?.repeatedIntervalType,
    values?.schedule?.day,
    values?.schedule?.startDate,
    values?.schedule?.endDate,
    values?.schedule?.startTime,
    values?.schedule?.endTime,
  ]);

  useEffect(() => {
    if (values.fee === 'free') {
      setValue('price', '0');
    }
  }, [setValue, values.fee]);

  useEffect(() => {
    if (!id) {
      if (values?.schedule?.repeatedIntervalType === 'once') {
        setValue('schedule.endDate', values?.schedule.startDate);
      }
    }
  }, [
    id,
    setValue,
    values.fee,
    values?.schedule?.repeatedIntervalType,
    values?.schedule.startDate,
  ]);


  useEffect(() => {
    if (values.fee === 'paid') {
      setValue('isReservationRequired', true);
    } else if (values.fee === 'free') {
      setValue('isReservationRequired', false);
    }
  }, [setValue, values.fee]);

  useEffect(() => {
    if (values.isReservationRequired === false) {
      setValue('hoursBeforeReservationExpires', 0);
    }
  }, [setValue, values.isReservationRequired]);


  const onSubmit = async () => {

    setActivityCreateLoading(true)

    if (id) {

      try {

        const response = await handleFetchData({
          data: graphqlApi.activity.mutations.activitiesForModerator.updateActivity({
            values,
            activityId: id,
          }),
          url: '/activity/graphql',
          accessToken,
        });

        if (response?.data?.data?.moderator_updateActivity?._id) {
          enqueueSnackbar('Güncelleme başarılı!');
          handleNext();
        }

        if (response?.data?.errors?.length) {
          response?.data?.errors?.map((item) => {
            if (item?.message === 'AutenticationError:OnlyActivityOwnerCanUpdate') {
              enqueueSnackbar('Başkasına ait aktivite güncellenemez!', {
                variant: 'error',
              });
            } else {
              enqueueSnackbar('Aktivite güncellenemedi!', {
                variant: 'error',
              });
            }

            return null;
          });
        }

        if (response?.response?.data?.errors?.length) {
          response?.response?.data?.errors?.map((item) => {
            enqueueSnackbar(item?.message, {
              variant: 'error',
            });
            return null;
          });
        }

      } catch (error) {

        console.error(error);
        enqueueSnackbar('Kayıt başarısız!', {
          variant: 'error',
        });

      }

    } else {

      try {

        const response = await handleFetchData({
          data: graphqlApi.activity.mutations.activitiesForModerator.createActivity({
            values,
          }),
          url: '/activity/graphql',
          accessToken,
        });

        if (response?.data?.data?.moderator_createActivity?._id) {
          enqueueSnackbar('Kayıt başarılı!');
          handleNext();
        }

        if (response?.data?.errors?.length) {
          enqueueSnackbar('Aktivite oluşturulamadı!', {
            variant: 'error',
          });

          // response?.data?.errors?.map(item => {
          //     enqueueSnackbar(item?.message, {
          //         variant: 'error'
          //     });
          //     return null
          // })
        }

        if (response?.response?.data?.errors?.length) {

          enqueueSnackbar('Hatalı ya da eksik alan var, aktivite oluşturulamadı!', {
            variant: 'error',
          });
          // response?.response?.data?.errors?.map(item => {
          //     enqueueSnackbar(item?.message, {
          //         variant: 'error'
          //     });
          //     return null
          // })
        }

      } catch (error) {

        console.error(error);
        enqueueSnackbar('Kayıt başarısız!', {
          variant: 'error',
        });

      }

    }

    setActivityCreateLoading(false)

  };

  const [selectedDate, setSelectedDate] = useState(null);
  const handleRemoveFromDate = (date) => {
    const filteredDates = values?.schedule?.days?.filter((item) => item?.date !== date);
    setValue('schedule.days', filteredDates);
  };

  const handleEditDate = (date) => {
    addOrUpdateDatePopup.onTrue();
    setSelectedDate(date);
  };

  const handleAddDate = () => {
    setSelectedDate(null);
    addOrUpdateDatePopup.onTrue();
  };

  const handleAddOrUpdateDate = (data) => {

    if (selectedDate) {

      const newDates = values?.schedule?.days?.map((item) => {
        return item?.date === selectedDate?.date ? data : item;
      });
      setValue('schedule.days', newDates);

    } else {
      const newDates = [...(values?.schedule?.days ?? []), data];
      setValue('schedule.days', newDates);
    }
    addOrUpdateDatePopup.onFalse();
  };

  const renderOrganizerOnboardingPages = useCallback(() => {
    switch (activeStep) {
      case 0:
        return <FirstPage />;
      case 1:
        return <FormOne values={values} setValue={setValue} />;
      case 2:
        return !id ? <FormTwo values={values} setValue={setValue} control={control} /> : null;
      case 3:
        return <LastPage />;
      default:
        return <FirstPage />;
    }
  }, [activeStep, control, id, setValue, values]);


  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>

      <CustomBreadcrumbs
        heading={id ? 'Aktivite Güncelle' : 'Aktivite Oluştur'}
        sx={{
          my: { xs: 3, md: 5 },
        }}
        action={
          <IconButton
            variant="contained"
            onClick={() => setIsReview(true)}
            sx={{
              display: { xs: 'flex', sm: 'none' },
              position: 'fixed',
              top: 90,
              right: 20,
              zIndex: 10,
              bgcolor: 'primary.main',
              color: 'white',
              '&:hover': {
                color: 'secondary.main',
                bgcolor: 'primary.light',

              }
            }}
          >
            {/* Ön İzleme */}
            <RemoveRedEye />
          </IconButton>
        }
      />

      <Grid container spacing={3} sx={{ mt: 5 }}>

        <Grid item xs={12} md={6}>
          <Card
            sx={{
              p: { xs: 0, sm: 3, md: 5 },
              pt: { xs: 0, sm: 5, md: 10 },
              maxWidth: '900px',
              boxShadow: { xs: 'none', sm: '1px 1px 25px 0px #ddd' },
              borderRadius: { xs: 0, sm: 3 },
            }}
          >

            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<ColorlibConnector />}
              sx={{
                width: '100%',
                transition: 'all',
                transitionDuration: '1s',
                mb: 5,
              }}
            >
              {steps.map((label, index) => {
                return (
                  <Step key={label} sx={{ transitionDuration: '1s' }}>
                    <StepLabel
                      StepIconComponent={ColorlibStepIcon}
                      sx={{ transitionDuration: '1s' }}
                    >
                      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>{label}</Box>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack
                spacing={3}
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              >

                <Box>{renderOrganizerOnboardingPages()}</Box>

                <Box
                  sx={{
                    display: activeStep === 3 ? 'none' : 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >

                  <LoadingButton
                    loading={activityCreateLoading}
                    variant="contained"
                    disabled={activeStep === 0}
                    onClick={() => {
                      animateScrollTo(0, { maxDuration: 1000 })
                      handleBack();
                    }}
                  >
                    Geri
                  </LoadingButton>

                  <LoadingButton
                    loading={activityCreateLoading}
                    disabled={Boolean(
                      activeStep === 3 ||
                      (activeStep === 1 && (
                        errors.listingId
                        ||
                        errors.locationId ||
                        errors.additionalDetails ||
                        errors.price ||
                        errors.quota ||
                        errors.duration ||
                        errors.fee ||
                        errors.hoursBeforeReservationExpires
                      ))
                    )}
                    variant="contained"
                    onClick={() => {

                      animateScrollTo(0, { maxDuration: 1000 })

                      if (activeStep === 1) {
                        trigger([
                          'listingId',
                          'locationId',
                          'additionalDetails',
                          'price',
                          'quota',
                          'duration',
                          'fee',
                          'hoursBeforeReservationExpires',
                        ]);
                        if (values.listingId) {
                          handleNext();
                        }
                      } else if (activeStep === 2) {
                        handleSubmit(onSubmit());
                      } else {
                        handleNext();
                      }

                    }}
                  >
                    {activeStep !== 3 ? 'İleri' : 'Kaydet'}
                  </LoadingButton>

                </Box>

              </Stack>
            </FormProvider>

          </Card>
        </Grid>

        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', sm: 'block' } }}>

          <CreateActivityCard values={values} />

          {values?.schedule?.days?.length ? (
            <Stack sx={{ mt: 5 }} spacing={1}>
              {values?.schedule?.days?.map((item, index) => {
                return (
                  <Card
                    key={index}
                    sx={{
                      p: { xs: '0px 15px', sm: '8px 15px' },
                      boxShadow: '1px 1px 10px 0px #ccc',
                    }}
                  >
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Typography>
                          {new Intl.DateTimeFormat('tr', {
                            dateStyle: 'medium',
                          }).format(new Date(item?.date))}
                        </Typography>
                        <Typography>
                          {' '}
                          {timeMapping.find((timeItem) => timeItem?.value === item?.startTime)
                            ?.name ?? ' ... '}{' '}
                        </Typography>{' '}
                        -
                        <Typography>
                          {' '}
                          {timeMapping.find((timeItem) => timeItem?.value === item?.endTime)
                            ?.name ?? ' ... '}{' '}
                        </Typography>
                      </Stack>

                      {
                        activeStep !== 4 && (
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Tooltip title="Güncelle" placement="top" arrow>
                              <IconButton
                                color={addOrUpdateDatePopup.value ? 'inherit' : 'default'}
                                onClick={() => handleEditDate(item)}
                              >
                                <Iconify icon="tabler:edit" />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="sil" placement="top" arrow>
                              <IconButton
                                color="default"
                                onClick={() => handleRemoveFromDate(item?.date)}
                                sx={{ color: 'error.main' }}
                              >
                                <Iconify icon="entypo:cross" />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        )
                      }
                    </Stack>
                  </Card>
                );
              })}
              {activeStep !== 3 ? (
                <ButtonBase
                  onClick={handleAddDate}
                  sx={{
                    border: '1px dashed #ccc',
                    borderRadius: '12px',
                    p: 2,
                    mt: 2,
                    '&:active': {
                      border: 'none',
                      boxShadow: '1px 1px 10px 0px #ccc',
                    },
                    '& svg': {
                      transform: 'scale(1.2)',
                    },
                    '&:active svg': {
                      transform: 'scale(1.3)',
                    },
                  }}
                >
                  <Iconify icon="mingcute:add-fill" />
                </ButtonBase>
              ) : null}
            </Stack>
          ) : (
            <Skeleton
              height={50}
              sx={{
                mt: 3,
              }}
            />
          )}

          <AddAndUpdateNewDatePopup
            selectedDate={selectedDate}
            open={addOrUpdateDatePopup.value}
            onClose={addOrUpdateDatePopup.onFalse}
            handleAddOrUpdateDate={handleAddOrUpdateDate}
          />

        </Grid>

        <Dialog
          open={isReview}
          onClose={() => setIsReview(false)}
          sx={{
            '&>*>*': {
              p: 2,
              m: 2,
              width: '100%',
            },
          }}
        >
          <CreateActivityCard values={values} />

          {values?.schedule?.days?.length ? (
            <Stack sx={{ mt: 5 }} spacing={1}>
              {values?.schedule?.days?.map((item, index) => {
                return (
                  <Card
                    key={index}
                    sx={{
                      p: { xs: '0px 15px', sm: '0px 15px' },
                      boxShadow: '1px 1px 10px 0px #ccc',
                      borderRadius: { xs: 1, sm: 2 },

                      '& *': {
                        fontSize: { xs: 13, sm: 16 },
                      },
                    }}
                  >
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Typography>
                          {new Intl.DateTimeFormat('tr', {
                            dateStyle: 'medium',
                          }).format(new Date(item?.date))}
                        </Typography>
                        <Typography>
                          {' '}
                          {timeMapping.find((timeItem) => timeItem?.value === item?.startTime)
                            ?.name ?? ' ... '}{' '}
                        </Typography>{' '}
                        -
                        <Typography>
                          {' '}
                          {timeMapping.find((timeItem) => timeItem?.value === item?.endTime)
                            ?.name ?? ' ... '}{' '}
                        </Typography>
                      </Stack>

                      {
                        activeStep !== 4 && (
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Tooltip title="Güncelle" placement="top" arrow>
                              <IconButton
                                color={addOrUpdateDatePopup.value ? 'inherit' : 'default'}
                                onClick={() => handleEditDate(item)}
                              >
                                <Iconify icon="tabler:edit" />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="sil" placement="top" arrow>
                              <IconButton
                                color="default"
                                onClick={() => handleRemoveFromDate(item?.date)}
                                sx={{ color: 'error.main' }}
                              >
                                <Iconify icon="entypo:cross" />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        )
                      }
                    </Stack>
                  </Card>
                );
              })}
              {activeStep !== 3 ? (
                <ButtonBase
                  onClick={handleAddDate}
                  sx={{
                    border: '1px dashed #ccc',
                    borderRadius: '12px',
                    p: 2,
                    mt: 2,
                    '&:active': {
                      border: 'none',
                      boxShadow: '1px 1px 10px 0px #ccc',
                    },
                    '& svg': {
                      transform: 'scale(1.2)',
                    },
                    '&:active svg': {
                      transform: 'scale(1.3)',
                    },
                  }}
                >
                  <Iconify icon="mingcute:add-fill" />
                </ButtonBase>
              ) : null}
            </Stack>
          ) : (
            <Skeleton
              height={50}
              sx={{
                mt: 3,
              }}
            />
          )}

          <AddAndUpdateNewDatePopup
            selectedDate={selectedDate}
            open={addOrUpdateDatePopup.value}
            onClose={addOrUpdateDatePopup.onFalse}
            handleAddOrUpdateDate={handleAddOrUpdateDate}
          />
        </Dialog>

      </Grid>

    </Container>
  );
};

const AddAndUpdateNewDatePopup = ({
  selectedDate,
  open,
  other,
  onClose,
  handleAddOrUpdateDate,
}) => {

  const NewActivitySchema = Yup.object().shape({
    date: null,
    day: null,
    startTime: null,
    endTime: null,
  });

  const defaultValues = useMemo(
    () => ({
      date: selectedDate?.date ? new Date(selectedDate?.date) : null,
      day: selectedDate?.day ?? '',
      startTime: selectedDate?.startTime ?? '',
      endTime: selectedDate?.endTime ?? '',
    }),
    [selectedDate]
  );

  const methods = useForm({
    resolver: yupResolver(NewActivitySchema),
    defaultValues,
    mode: 'all',
  });

  const { watch, handleSubmit, setValue, control, reset } = methods;

  const values = watch();

  useEffect(() => {
    if (values.date) {
      setValue('day', new Date(values?.date).getDay());
    }
  }, [setValue, values.date]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset, selectedDate]);

  const onSubmit = (data) => {
    handleAddOrUpdateDate(data);
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ pb: 2 }}>{selectedDate ? 'Güncelle' : 'Ekle'}</DialogTitle>

        <DialogContent sx={{ typography: 'body2' }}>
          <Grid container spacing={3} sx={{ pt: 2 }}>
            <Grid item xs={12}>
              <Controller
                name="date"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    format="dd/MM/yyyy"
                    className="date"
                    label="Aktivite tarihi"
                    minDate={new Date()}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFSelect name="startTime" label="Başlama saati">
                {timeMapping.map((status) => (
                  <MenuItem
                    key={status?.value}
                    value={status?.value}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {status?.name?.toLocaleLowerCase()}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFSelect name="endTime" label="Bitiş saati">
                {timeMapping.map((status) => (
                  <MenuItem
                    key={status?.value}
                    value={status?.value}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {status?.name?.toLocaleLowerCase()}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
            Ekle
          </Button>

          <Button variant="outlined" color="inherit" onClick={onClose}>
            iptal
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

const FirstPage = () => {
  const { id } = useParams();

  return (
    <Box
      sx={{
        minHeight: '100px',
        py: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <IconButton
        sx={{
          backgroundColor: (theme) => theme.palette.primary.light,
          p: 2,
          mb: 2,
        }}
      >
        <CelebrationIcon
          sx={{
            width: '100px',
            height: '100px',
            color: (theme) => theme.palette.primary.main,
          }}
        />
      </IconButton>
      <Typography
        sx={{
          fontSize: 35,
          fontFamily: 'sen !important',
          color: (theme) => theme.palette.primary.main,
        }}
      >
        {id ? 'Aktivite Güncelle' : 'Yeni Aktivite Oluştur'}
      </Typography>
      <Typography
        sx={{
          fontSize: 20,
          fontFamily: 'sen !important',
          color: '#999',
          textAlign: 'center',
          display: { xs: 'none', sm: 'block' }
        }}
      >
        Oluşturduğunuz aktivitenin ön izlemesini sağ tarafta görüntüleyebilirsiniz.
      </Typography>
    </Box>
  );
};

const FormOne = ({ setValue, values }) => {
  const { listings, locations } = useSelector((state) => state.parameter);
  const { selectedListingForModerator } = useSelector(state => state.listing)
  const [quotaType, setQuotaType] = useState('limited');

  const handleQuotaTypeChange = (event) => {
    const selectedQuotaType = event.target.value;
    setQuotaType(selectedQuotaType);

    if (selectedQuotaType === 'unlimited') {
      setValue('quota', 100000);
    }
  };


  useEffect(() => {
    if (values?.listingId) {
      setValue('title', listings.find((item) => item?._id === values?.listingId)?.title);
    }
  }, [listings, setValue, values.listing, values?.listingId]);

  useEffect(() => {
    if (selectedListingForModerator && selectedListingForModerator?.format !== "faceToFace") {
      console.log(selectedListingForModerator?.format, 'zzzz')
      setValue('locationId', process.env.REACT_APP_ONLINE_LOCATION_ID);
    } else {
      setValue('locationId', values?.locationId ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedListingForModerator?.format, setValue]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <RHFSelect name="listingId" label="Listeleme">
          {listings?.map((status) => (
            <MenuItem key={status?._id} value={status?._id} sx={{ textTransform: 'capitalize' }}>
              {status?.title?.toLocaleLowerCase()}
            </MenuItem>
          ))}
        </RHFSelect>
      </Grid>

      <Grid item xs={12} md={6}>
        <RHFSelect name="locationId" label="Lokasyon" disabled={selectedListingForModerator && selectedListingForModerator?.format !== "faceToFace"}>
          {locations?.map((status) => (
            <MenuItem key={status?._id} value={status?._id} sx={{ textTransform: 'capitalize' }}>
              {status?.address?.title?.toLocaleLowerCase()}
            </MenuItem>
          ))}

          {
            selectedListingForModerator && selectedListingForModerator?.format !== "faceToFace" && (
              <MenuItem value={process.env.REACT_APP_ONLINE_LOCATION_ID} sx={{ textTransform: 'capitalize' }}>
                online
              </MenuItem>
            )
          }
        </RHFSelect>
      </Grid>

      <Grid item xs={12}>
        <RHFTextField name="title" label="Başlık" />
      </Grid>

      <Grid item xs={12} md={6}>
        {/* <RHFTextField type="number" name="duration" label="Süre" /> */}
        <RHFSelect name="duration" label="Süre">
          {[
            { value: 10, name: '10 dk' },
            { value: 20, name: '20 dk' },
            { value: 30, name: '30 dk' },
            { value: 40, name: '40 dk' },
            { value: 50, name: '50 dk' },
            { value: 60, name: '60 dk' },
            { value: 70, name: '70 dk' },
            { value: 80, name: '80 dk' },
            { value: 90, name: '90 dk' },
          ]?.map((status) => (
            <MenuItem
              key={status?.value}
              value={status?.value}
              sx={{ textTransform: 'capitalize' }}
            >
              {status?.name?.toLocaleLowerCase()}
            </MenuItem>
          ))}
        </RHFSelect>
      </Grid>

      <Grid item xs={quotaType === "limited" ? 8 : 12} md={quotaType === "limited" ? 3 : 6}>
        <RHFSelect
          name="quotaType"
          label="Kontenjan Türü"
          value={quotaType}
          onChange={handleQuotaTypeChange}
        >
          <MenuItem value="limited">Sınırlı</MenuItem>
          <MenuItem value="unlimited">Sınırsız</MenuItem>
        </RHFSelect>
      </Grid>

      {quotaType === 'limited' ? (
        <Grid item xs={4} md={3}>
          <RHFTextField
            type="number"
            name="quota"
            label="Kontenjan"
            value={values?.quota ?? 0}
            InputLabelProps={{ 
              shrink: true 
            }}
          />
        </Grid>
      ) : undefined}

      <Grid item xs={12} md={6}>
        <RHFSelect name="fee" label="Ücret seçeneği">
          <MenuItem value="free" onClick={() => setValue('price', 0)}>
            Ücretsiz
          </MenuItem>
          <MenuItem value="paid">Ücretli</MenuItem>
        </RHFSelect>
      </Grid>

      {values.fee === 'free' && (
        <Grid item xs={12} md={6}>
          <RHFSelect
            name="isReservationRequired"
            label="Rezervasyon Türü"
            onChange={(e) => setValue('isReservationRequired', e.target.value === true)}
          >
            <MenuItem value={Boolean(true)}>Zorunlu</MenuItem>
            <MenuItem value={Boolean(false)}>Zorunlu Değil</MenuItem>
          </RHFSelect>
        </Grid>
      )}



      {values.fee === 'paid' &&
        <Grid item xs={12} md={6}>
          <Stack spacing={0} direction="row" alignItems="center">
            {/* <RHFCheckbox name="fee"
                        label="Ücretli mi?"
                        onClick={(e) => {
                            if (!e.target.value) {
                                setValue("price", 0)
                            }
                        }}
                        sx={{
                            whiteSpace: 'nowrap'
                        }}
                    /> */}
            <RHFTextField
              type="number"
              name="price"
              label="Ücret"
              disabled={values?.fee === 'free'}
            />
          </Stack>
        </Grid>}

      {values.isReservationRequired === true && <Grid item xs={12}>
        {/* <RHFTextField type="number" name="hoursBeforeReservationExpires" label="Son rezervasyon başlamadan ne kadar önce?" /> */}
        <RHFSelect name="hoursBeforeReservationExpires" label="Son rezervasyon saati">
          {hoursBeforeReservationExpiresList?.map((status) => (
            <MenuItem
              key={status?.value}
              value={status?.value}
              sx={{ textTransform: 'capitalize' }}
            >
              {status?.name?.toLocaleLowerCase()}
            </MenuItem>
          ))}
        </RHFSelect>
      </Grid>}

      <Grid item xs={12}>
        <RHFTextField multiline rows={4} name="additionalDetails" label="Ek Açıklama" />
      </Grid>
    </Grid>
  );
};

const FormTwo = ({ values, setValue, control }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <RHFCheckbox name="schedule.isRepetetive" label="Çok günlü bir aktivite mi?" />
      </Grid>

      {!values?.schedule?.isRepetetive ? (
        <>
          <Grid item xs={12}>
            <Controller
              name="schedule.startDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  format="dd/MM/yyyy"
                  className="date"
                  label="Aktivite tarihi"
                  minDate={new Date()}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <RHFSelect name="schedule.startTime" label="Başlama saati">
              {timeMapping
                .slice(0, timeMapping.length - Math.ceil(Number(values.duration / 30)))
                .map((status) => (
                  <MenuItem
                    key={status?.value}
                    value={status?.value}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {status?.name?.toLocaleLowerCase()}
                  </MenuItem>
                ))}
            </RHFSelect>
          </Grid>

          <Grid item xs={12} md={6}>
            <RHFSelect name="schedule.endTime" label="Bitiş saati">
              {timeMapping
                .slice(
                  timeMapping
                    .map((item) => item.value)
                    .indexOf(values.schedule.startTime + Math.ceil(Number(values.duration / 30)))
                )
                .map((status) => (
                  <MenuItem
                    key={status?.value}
                    value={status?.value}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {status?.name?.toLocaleLowerCase()}
                  </MenuItem>
                ))}
            </RHFSelect>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <RHFSelect name="schedule.repeatedIntervalType" label="Aktivite aralığı">
              {repeatIntervalTypes.map((status, index) => (
                <MenuItem
                  key={status?.value}
                  value={status?.value}
                  sx={{ textTransform: 'capitalize' }}
                >
                  {status?.name?.toLocaleLowerCase()}
                </MenuItem>
              ))}
            </RHFSelect>
          </Grid>

          {(values?.schedule?.repeatedIntervalType === 'weekly' ||
            values?.schedule?.repeatedIntervalType === 'monthly') && (
              <Grid item xs={12}>
                {/* <RHFSelect name="schedule.day" label="Aktivite günü">
                                        {dayMapping.map((status) => (
                                            <MenuItem key={status?.value} value={status?.value} sx={{ textTransform: 'capitalize' }}>
                                                {status?.name?.toLocaleLowerCase()}
                                            </MenuItem>
                                        ))}
                                    </RHFSelect> */}

                <RHFAutocomplete
                  name="schedule.day"
                  placeholder="Haftanın hangi günleri?"
                  multiple
                  disableCloseOnSelect
                  options={dayMapping}
                  getOptionLabel={(option) => option?.value?.toString()}
                  isOptionEqualToValue={(option, value) => option?.value === value?.value}
                  renderOption={(props, option) => (
                    <li {...props} key={option?.value}>
                      {option?.name}
                    </li>
                  )}
                  renderTags={(selected, getTagProps) =>
                    selected.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={option?.value}
                        label={option?.name}
                        size="small"
                        color="info"
                        variant="soft"
                      />
                    ))
                  }
                />
              </Grid>
            )}

          <Grid item xs={12} md={6}>
            <Controller
              name="schedule.startDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  format="dd/MM/yyyy"
                  className="date"
                  label="Başlangıç tarihi"
                  minDate={new Date()}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="schedule.endDate"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  format="dd/MM/yyyy"
                  className="date"
                  label="Bitiş tarihi"
                  minDate={new Date()}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <RHFSelect name="schedule.startTime" label="Başlama saati">
              {timeMapping
                .slice(0, timeMapping.length - Math.ceil(Number(values.duration / 30)))
                .map((status) => (
                  <MenuItem
                    key={status?.value}
                    value={status?.value}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {status?.name?.toLocaleLowerCase()}
                  </MenuItem>
                ))}
            </RHFSelect>
          </Grid>

          <Grid item xs={12} md={6}>
            <RHFSelect name="schedule.endTime" label="Bitiş saati">
              {timeMapping
                .slice(
                  timeMapping
                    .map((item) => item.value)
                    .indexOf(values.schedule.startTime + Math.ceil(Number(values.duration / 30)))
                )
                .map((status) => (
                  <MenuItem
                    key={status?.value}
                    value={status?.value}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {status?.name?.toLocaleLowerCase()}
                  </MenuItem>
                ))}
            </RHFSelect>
          </Grid>
        </>
      )}

      {/* <Grid item xs={12} md={6}>
                <Controller
                    name="excludedDates"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <DateRangePickerDay
                            {...field}
                            format="dd/MM/yyyy"
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: !!error,
                                    helperText: error?.message,
                                },
                            }}
                        />
                    )}
                />
            </Grid> */}

      {/* <Grid item xs={12} >
                <Grid container spacing={3} >

                    <Grid item xs={12}>
                        <Typography variant="subtitle2">monthlyDates</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="monthlyDates.startDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                    {...field}
                                    format="dd/MM/yyyy"
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="monthlyDates.endDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TimePicker
                                    {...field}
                                    // format="dd/MM/yyyy"
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid> */}

      {/* <Grid item xs={12} >
                <Grid container spacing={3} >

                    <Grid item xs={12}>
                        <Typography variant="subtitle2">weeklyDates</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="weeklyDates.startDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <DatePicker
                                    {...field}
                                    format="dd/MM/yyyy"
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Controller
                            name="weeklyDates.endDate"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TimePicker
                                    {...field}

                                    // format="dd/MM/yyyy"
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            error: !!error,
                                            helperText: error?.message,
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid> */}
    </Grid>
  );
};

const LastPage = () => {
  const { id } = useParams();

  return (
    <Box
      sx={{
        minHeight: '100px',
        py: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',

        flexDirection: 'column',
      }}
    >
      <IconButton
        sx={{
          backgroundColor: (theme) => theme.palette.primary.light,
          p: 2,
          mb: 5,
        }}
      >
        <ThumbUpRoundedIcon
          sx={{
            width: '100px',
            height: '100px',
            color: (theme) => theme.palette.primary.main,
          }}
        />
      </IconButton>
      <Typography
        sx={{
          fontSize: 40,
          fontFamily: 'sen !important',
          color: (theme) => theme.palette.primary.main,
        }}
      >
        {id ? 'Başarılı bir şekilde güncellenmiştir.' : 'Başarılı bir şekilde oluşturulmuştur.'}
      </Typography>

      <Button variant="contained" color="primary" sx={{ mt: 5 }} size='large'
        onClick={() => {
          window.location.href = '/aktiviteler/olustur';
        }
        }
      >
        Yeni Aktivite Oluştur
      </Button>
    </Box>
  );
};

export default CreateActivity;
