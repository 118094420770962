/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */
import { Grid, MenuItem } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { RHFSelect, RHFTextField } from 'src/components/hook-form'
import { hoursBeforeReservationExpiresList } from 'src/hophop/utils/_enums'

const FormOne = ({ values, setValue }) => {

    const [quotaType, setQuotaType] = useState('limited');

    const handleQuotaTypeChange = (event) => {
        const selectedQuotaType = event.target.value;
        setQuotaType(selectedQuotaType);

        if (selectedQuotaType === 'unlimited') {
            setValue('quota', 100000);
        }
    };

    useEffect(() => {
        if (values.fee === 'paid') {
            setValue('isReservationRequired', true);
        } else if (values.fee === 'free') {
            setValue('isReservationRequired', false);
        }
    }, [setValue, values.fee]);

    useEffect(() => {
        if (values.isReservationRequired === false) {
            setValue('hoursBeforeReservationExpires', 0);
        }
    }, [setValue, values.isReservationRequired]);

    return (

        <Grid container spacing={3}>


            {/* <Grid item xs={12} md={6}>
                <RHFSelect name="locationId" label="Lokasyon">
                    {locations?.map((status) => (
                        <MenuItem key={status?._id} value={status?._id} sx={{ textTransform: 'capitalize' }}>
                            {status?.address?.title?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid> */}

            <Grid item xs={12}>
                <RHFTextField name="title" label="Aktivite İsmi" />
            </Grid>

            <Grid item xs={12} md={6}>
                <RHFSelect name="duration" label="Süre">
                    {[
                        { value: 10, name: '10 dk' },
                        { value: 20, name: '20 dk' },
                        { value: 30, name: '30 dk' },
                        { value: 40, name: '40 dk' },
                        { value: 50, name: '50 dk' },
                        { value: 60, name: '60 dk' },
                        { value: 70, name: '70 dk' },
                        { value: 80, name: '80 dk' },
                        { value: 90, name: '90 dk' },
                    ]?.map((status) => (
                        <MenuItem
                            key={status?.value}
                            value={status?.value}
                            sx={{ textTransform: 'capitalize' }}
                        >
                            {status?.name?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid>

            {/* Kontenjan Türü (Sınırlı / Sınırsız) */}
            <Grid item xs={quotaType === "limited" ? 8 : 12} md={quotaType === "limited" ? 3 : 6}>
                <RHFSelect
                    name="quotaType"
                    label="Kontenjan Türü"
                    value={quotaType}
                    onChange={handleQuotaTypeChange}
                >
                    <MenuItem value="limited">Sınırlı</MenuItem>
                    <MenuItem value="unlimited">Sınırsız</MenuItem>
                </RHFSelect>
            </Grid>

            {/* Kontenjan Sayısı */}
            {quotaType === 'limited' && (
                <Grid item xs={4} md={3}>
                    <RHFTextField type="number" name="quota" label="Kontenjan" />
                </Grid>
            )}

            {/* Ücret seçeneği */}
            <Grid item xs={12} md={6}>
                <RHFSelect name="fee" label="Ücret seçeneği">
                    <MenuItem value="free" onClick={() => setValue('price', 0)}>Ücretsiz</MenuItem>
                    <MenuItem value="paid">Ücretli</MenuItem>
                </RHFSelect>
            </Grid>

            {values.fee === 'free' && (
                <Grid item xs={12} md={6}>
                    <RHFSelect
                        name="isReservationRequired"
                        label="Rezervasyon Türü"
                        onChange={(e) => setValue('isReservationRequired', e.target.value === true)}
                    >
                        <MenuItem value={Boolean(true)}>Zorunlu</MenuItem>
                        <MenuItem value={Boolean(false)}>Zorunlu Değil</MenuItem>
                    </RHFSelect>
                </Grid>
            )}

            {/* Ücret */}
            {values.fee === 'paid' && <Grid item xs={12} md={6}>
                <RHFTextField
                    type="number"
                    name="price"
                    label="Ücret"
                    disabled={values?.price === '0'}
                />
            </Grid>}

            {/* Son rezervasyon saati */}
            {values.isReservationRequired === true && <Grid item xs={12}>
                {/* <RHFTextField type="number" name="hoursBeforeReservationExpires" label="Son rezervasyon başlamadan ne kadar önce?" /> */}
                <RHFSelect name="hoursBeforeReservationExpires" label="Son rezervasyon saati">
                    {hoursBeforeReservationExpiresList?.map((status) => (
                        <MenuItem
                            key={status?.value}
                            value={status?.value}
                            sx={{ textTransform: 'capitalize' }}
                        >
                            {status?.name?.toLocaleLowerCase()}
                        </MenuItem>
                    ))}
                </RHFSelect>
            </Grid>}

            <Grid item xs={12}>
                <RHFTextField multiline rows={4} name="additionalDetails" label="Ek Açıklama" />
            </Grid>

        </Grid>


    )
}


export default FormOne